import React from 'react'
import { graphql } from 'gatsby'
import { MinimalLayout } from '../components'
import Helmet from 'react-helmet'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, site } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  return (
    <MinimalLayout>
      <Helmet>
        <title>
          {site.siteMetadata.title} | {frontmatter.title}
        </title>
        {/* OpenGL MetaData */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={markdownRemark.excerpt} />
        {/* Twitter Metadata */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={markdownRemark.excerpt} />
      </Helmet>
      <div
        style={{
          width: '100%',
          margin: '70px auto',
          maxWidth: 960,
          padding: '1rem',
        }}
      >
        <article>
          <h1>[ {frontmatter.title} ]</h1>
          <hr />
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </div>
    </MinimalLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD YYYY")
        path
        title
      }
    }
  }
`
